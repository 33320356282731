import httpClient from '../../../api/httpClient';
import ForgotPasswoedModel from '../models/ForgotPasswordModel';
import ForgotPasswordAndUpdatePasswordResponseModel from '../models/ForgotPasswordAndUpdatePasswordResponseModel';

const ENDPOINT_URL = '/market/send-forgot-password-mail';

export const sendForgotPasswordMail = (email: ForgotPasswoedModel) => {
  return httpClient
    .post(ENDPOINT_URL, email)
    .then((response) => new ForgotPasswordAndUpdatePasswordResponseModel(response))
    .catch((error) => error);
};
