import Vue from 'vue';
import { defineComponent, reactive, ref } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidInput, ZidButton, ZidIcon } from '@zidsa/ui';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import { sendForgotPasswordMail } from '../../api/forgotPassword';
import ForgotPasswoedModel from '../../models/ForgotPasswordModel';
import ForgotPasswordAndUpdatePasswordResponseModel from '../../models/ForgotPasswordAndUpdatePasswordResponseModel';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';

export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    ZidButton,
    ZidIcon,
  },
  setup() {
    const loadingSubmit = ref(false);
    const formState = reactive({
      emailAddress: '',
    });
    const rules = {
      emailAddress: {
        required,
        email,
        emailAddress: helpers.withMessage('E-mail is not valid', required),
      },
    };

    const v$ = useVuelidate(rules, formState);

    const onSubmit = async () => {
      if (!v$.value.$invalid) {
        loadingSubmit.value = true;
        const forgotPasswordData = new ForgotPasswoedModel({
          email: formState.emailAddress,
        });

        const response = await sendForgotPasswordMail(forgotPasswordData);

        if (response.status === 'success') {
          const responseModel = new ForgotPasswordAndUpdatePasswordResponseModel(response);
          router.replace({ name: RoutingRouteEnum.LogIn });
          Vue.$toast.info(``, {
            message: `Please check your E-mail`,
            position: 'top',
          });
        } else {
          Vue.$toast.error(``, {
            message: 'Error in sending Email, please try again later',
            position: 'top',
          });
        }
        loadingSubmit.value = false;
      }
    };

    const onEmailChange = (changedValue: Record<string, string>) => {
      v$.value.emailAddress.$touch();
      formState.emailAddress = changedValue.value;
    };

    return {
      formState,
      v$,
      loadingSubmit,
      onSubmit,
      onEmailChange,
    };
  },
});
