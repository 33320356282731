import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidInput, ZidCheckbox, ZidButton } from '@zidsa/ui';
import { useLogin } from '../../helpers/hooks/useLogin';
import { loginPartner } from '../../api/login';
import LoginModel from '../../models/LoginModel';
import {
  saveAuthStateToLocalStorageAndGlobalState,
  saveGrantedPermissionsToLocalStorageAndGlobalState,
} from '@/api/useAuthStatus';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '@/store';
import ErrorModel from '@/api/models/ErrorModel';
import { getPermissions } from '@/api/top-level-apis/permissions/permissions';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

const { useActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    ZidCheckbox,
    ZidButton,
  },
  setup() {
    const loadingSubmit = ref(false);
    const { loginState, showPassword, v$, onDataChange, togglePasswordVisibility, onRememberMeChanged } = useLogin();
    const { setAccountCreationStepsState, setAnAppHasBeenCreated } = useActions([
      'setAccountCreationStepsState',
      'setAnAppHasBeenCreated',
    ]);

    const onSubmit = async () => {
      if (v$.value.$invalid) {
        Vue.$toast.error(``, { message: 'Please review your entries', position: 'top' });
        return;
      }

      loadingSubmit.value = true;

      const loginData = new LoginModel({
        email: loginState.emailAddress,
        password: loginState.password,
      });

      const response = await loginPartner(loginData);

      if (response instanceof ErrorModel || !response.partner) {
        Vue.$toast.error(`Failed to Log in, please try again later.`);
        loadingSubmit.value = false;
        return;
      }

      await saveAuthStateToLocalStorageAndGlobalState(response, false, false, loginState.remember);
      store.dispatch('setIsAppLoading', true);
      store.dispatch('setPartnerID', response.partner.id);

      const welcomeResponse = await welcomePartner();
      if (!(welcomeResponse instanceof ErrorModel) && welcomeResponse.payload) {
        await setAccountCreationStepsState(welcomeResponse.payload.partner_email);
        await setAnAppHasBeenCreated(welcomeResponse.payload.app_created);
        saveAuthStateToLocalStorageAndGlobalState(
          response,
          welcomeResponse.payload.partner_email,
          welcomeResponse.payload.app_created,
          loginState.remember,
        );
      }

      if (!response.partner.is_account_owner) {
        const permissionsRes = await getPermissions();
        if (!(permissionsRes instanceof ErrorModel) && permissionsRes.permissions)
          await saveGrantedPermissionsToLocalStorageAndGlobalState(permissionsRes.permissions, loginState.remember);
        else await saveGrantedPermissionsToLocalStorageAndGlobalState();
      }

      if (response.partner.role === null)
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          user_hash: response.partner.intercom_user_hash,
          user_id: `${response.partner.id}`,
          api_base: 'https://api-iam.intercom.io',
          zam_ID: response.partner.id,
          name: response.partner.name,
          email: response.partner.email,
          language_override: I18nLocaleEnum.en,
        });

      Vue.$toast.success(`Welcome to Partner Dashboard, ${response.partner.name}`);
      loadingSubmit.value = false;
      router.replace({ name: RoutingRouteEnum.Home }).catch(() => {
        /**/
      });
    };

    return {
      onSubmit,
      loginState,
      showPassword,
      v$,
      loadingSubmit,
      onDataChange,
      togglePasswordVisibility,
      onRememberMeChanged,
    };
  },
});
