import AuthReponseModel from '../models/AuthReponseModel';
import { setLocalStorge } from '@/helpers/localStorage';
import { AuthenticationResponse } from '../types/authTypes';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '@/store';
import { Userpilot } from 'userpilot';
import dayjs from 'dayjs';

const { useActions } = createNamespacedHelpers(store, 'authentication');

export const saveAuthStateToLocalStorageAndGlobalState = (
  response: AuthReponseModel,
  areAccountCreationStepsCompleted = false,
  hasAnAppBeenCreated = false,
) => {
  const {
    setPartnerName,
    setPartnerEmail,
    setPartnerRole,
    setXPartnerTokenState,
    setTokenExpirationDate,
    setPartnerAuthenticated,
    setIsAccountOwner,
    setAccountCreationStepsState,
    setAnAppHasBeenCreated,
    setPartnerIntercomHash,
  } = useActions([
    'setPartnerName',
    'setPartnerEmail',
    'setPartnerRole',
    'setPartnerAuthenticated',
    'setIsAccountOwner',
    'setXPartnerTokenState',
    'setTokenExpirationDate',
    'setAccountCreationStepsState',
    'setAnAppHasBeenCreated',
    'setPartnerIntercomHash',
  ]);
  // userpilot identification
  Userpilot.identify(`${response.partner.id}`, {
    name: response.partner.name,
    email: response.partner.email,
    created_at: dayjs().toISOString(),
    email_verified: areAccountCreationStepsCompleted,
    application_type: response.partner.application_type,
    looking_for_ideas: response.partner.looking_for_ideas,
    country: response.partner.country,
  });
  // local storage
  setLocalStorge(AuthenticationResponse.PartnerID, response.partner.id);
  setLocalStorge(AuthenticationResponse.Name, response.partner.name);
  setLocalStorge(AuthenticationResponse.Email, response.partner.email);
  setLocalStorge(AuthenticationResponse.Role, response.partner.role);
  setLocalStorge(AuthenticationResponse.PartnerToken, response.partner['x-partner-token']);
  setLocalStorge(AuthenticationResponse.Token_expiry_date, response.partner.token_expiry_date);
  setLocalStorge(AuthenticationResponse.IsAccountOwner, response.partner.is_account_owner);
  setLocalStorge('areAccountCreationStepsCompleted', areAccountCreationStepsCompleted);
  setLocalStorge('hasAnAppBeenCreated', hasAnAppBeenCreated);
  setLocalStorge(AuthenticationResponse.IntercomHash, response.partner.intercom_user_hash);
  // global store
  store.dispatch('setPartnerID', response.partner.id);
  setPartnerName(response.partner.name);
  setPartnerEmail(response.partner.email);
  setPartnerRole(response.partner.role);
  setXPartnerTokenState(response.partner['x-partner-token']);
  setTokenExpirationDate(response.partner.token_expiry_date);
  setPartnerAuthenticated(true);
  setIsAccountOwner(response.partner.is_account_owner);
  setAccountCreationStepsState(areAccountCreationStepsCompleted);
  setAnAppHasBeenCreated(hasAnAppBeenCreated);
  setPartnerIntercomHash(response.partner.intercom_user_hash);
};
