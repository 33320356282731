import httpClient from '../../httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PermissionsResponseModel from '@/api/models/PermissionsResponseModel';

const ENDPOINT_URL = '/market/partner-permissions';

export const getPermissions = (): Promise<PermissionsResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new PermissionsResponseModel(response))
    .catch((error) => error);
};
