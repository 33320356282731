import { ref, reactive } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength, email } from '@vuelidate/validators';

export const useLogin = () => {
  const loginState = reactive({
    emailAddress: '',
    password: '',
    remember: false,
  });
  const showPassword = ref(false);

  const rules = {
    emailAddress: {
      required,
      email,
      emailAddress: helpers.withMessage('E-mail is not valid', required),
    },
    password: {
      required,
      minLength: minLength(8),
      password: helpers.withMessage('Password is not valid', required),
    },
    rememeber: {},
  };

  const v$ = useVuelidate(rules, loginState);

  const onDataChange = (changedValues: Record<string, string>, dataProperty: 'emailAddress' | 'password') => {
    v$.value[dataProperty].$touch();
    loginState[dataProperty] = changedValues.value;
  };

  const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
  };

  const onRememberMeChanged = (value: Record<string, boolean>) => {
    loginState.remember = value.isChecked;
  };

  return {
    loginState,
    showPassword,
    v$,
    onDataChange,
    togglePasswordVisibility,
    onRememberMeChanged,
  };
};
