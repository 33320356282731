export const PARTNER_TYPES_LIST = [
  { label: 'An Independent Developer', value: 'independent' },
  { label: 'An Employed Developer (company)', value: 'employed' },
];

export const APPLICATION_INTENTIONS_LIST = [
  { label: 'Developing Public App (list on Zid App Market)', value: 'zid-app-market' },
  { label: 'Developing Private App (only for Professional and Enterprise packages)', value: 'enterprise-merchant' },
  { label: 'Developing Theme (list on Zid Theme Market)', value: 'theme-developer' },
];

export const IS_LOOKING_FOR_IDEAS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
