import Vue from 'vue';
import router from '@/router';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import useChangePassword from '../../helpers/hooks/useChangePassword';
import { ZidButton, ZidInput, ZidLoader } from '@zidsa/ui';
import BaseText from '../../../../components/text/BaseText.vue';
import { updatePartnerPassword } from '../../api/updatePassword';
import UpdatePasswordModel from '../../models/UpdatePasswordModel';
import ForgotPasswordAndUpdatePasswordResponseModel from '../../models/ForgotPasswordAndUpdatePasswordResponseModel';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { verifyEmail } from '../../api/emailOperations';

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    ZidInput,
    ZidLoader,
  },
  setup(_, context) {
    const loadingSubmit = ref(false);
    const isEmailVerified = ref(false);

    const { email, signature } = context.root.$route.query;

    const { formState, v$, isPasswordValid, isConfirmationInvalid, onDataChange } = useChangePassword();

    onBeforeMount(async () => {
      if (typeof email === 'string' && typeof signature === 'string') {
        const response = await verifyEmail(email, signature);
        if (response.status !== 'success') return;
        isEmailVerified.value = true;
      }
    });

    const onUpdatePasswordBtnClicked = async () => {
      if (!v$.value.$invalid && email && signature) {
        loadingSubmit.value = true;
        const updatePasswordData = new UpdatePasswordModel({
          email,
          password: formState.password,
          password_confirmation: formState.passwordConfirm,
          signature,
        });

        const response = await updatePartnerPassword(updatePasswordData);
        if (response.status === 'success') {
          Vue.$toast.info(``, {
            message: `Password has been updated`,
            position: 'top',
          });
        } else {
          Vue.$toast.error(``, {
            message: 'Error in updating password, please try again later',
            position: 'top',
          });
        }
        router.replace({ name: RoutingRouteEnum.LogIn }).catch(() => {
          //
        });
        loadingSubmit.value = false;
      } else {
        Vue.$toast.error(``, {
          message: 'Some entries are missing!',
          position: 'top',
        });
      }
    };

    return {
      formState,
      v$,
      isPasswordValid,
      isConfirmationInvalid,
      isEmailVerified,
      onDataChange,
      onUpdatePasswordBtnClicked,
    };
  },
});
