










import Vue from 'vue';
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { confirmEmail } from '../../api/emailOperations';
import { ZidLoader } from '@zidsa/ui';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    ZidLoader,
  },
  setup(_, { root }) {
    const isEmailConfirmed = ref(false);
    const { email, signature } = root.$route.query;
    onMounted(async () => {
      if (typeof email === 'string' && typeof signature === 'string') {
        const response = await confirmEmail(email, signature);
        if (response instanceof ErrorModel || response.status !== 'success') {
          Vue.$toast.error('', {
            position: 'top',
            message: response.message.description,
          });
          router.push({ name: RoutingRouteEnum.Home }).catch(() => {
            //
          });
          return;
        }

        isEmailConfirmed.value = true;
        Vue.$toast.success('', {
          position: 'top',
          message: 'E-mail has been confirmed',
        });
        router.push({ name: RoutingRouteEnum.Home }).catch(() => {
          //
        });
      }
    });
    return {
      isEmailConfirmed,
    };
  },
});
