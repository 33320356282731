import httpClient from '../../../api/httpClient';
import UpdatePasswordModel from '../models/UpdatePasswordModel';
import ForgotPasswordAndUpdatePasswordResponseModel from '../models/ForgotPasswordAndUpdatePasswordResponseModel';

const ENDPOINT_URL = '/market/change-password';

export const updatePartnerPassword = (updatePasswordInfo: UpdatePasswordModel) => {
  return httpClient
    .post(ENDPOINT_URL, updatePasswordInfo)
    .then((response) => new ForgotPasswordAndUpdatePasswordResponseModel(response))
    .catch((error) => error);
};
