import { defineComponent } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidTabs, ZidTabsHeader, ZidTabsBody, ZidTab, ZidTabItem } from '@zidsa/ui';
import AccountInfo from './account-info/AccountInfo.vue';

export default defineComponent({
  components: {
    BaseText,
    ZidTabs,
    ZidTabsHeader,
    ZidTabsBody,
    ZidTab,
    ZidTabItem,
    AccountInfo,
  },

  setup() {
    return {};
  },
});
