import BaseModel from './BaseModel';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';

class PermissionsResponseModel extends BaseModel {
  public permissions: RootPermissionsEnum[];

  constructor(arg: any) {
    super(arg);
    this.permissions = arg.permissions;
  }
}

export default PermissionsResponseModel;
