class LoginModel {
  public email: string;
  public password: string;

  constructor(arg: any) {
    this.email = arg.email;
    this.password = arg.password;
  }
}

export default LoginModel;
