import BaseModel from '../../../api/models/BaseModel';
import {
  PartnerRoleEnum,
  PartnerAppsType,
  PartnerAppIntentionValuesEnum,
  PartnerLookingForIdeasValuesEnum,
} from '@/types/models/partner.type';

class AuthReponseModel extends BaseModel {
  public partner: {
    id: number;
    'x-partner-token': string;
    name: string;
    email: string;
    role: null | PartnerRoleEnum;
    token_expiry_date: string;
    apps_types: PartnerAppsType;
    application_type: PartnerAppIntentionValuesEnum;
    looking_for_ideas: PartnerLookingForIdeasValuesEnum;
    is_account_owner: boolean;
    country: string;
    intercom_user_hash: string;
    created_at: string;
  };
  constructor(arg: any) {
    super(arg);
    this.partner = arg.partner;
  }
}

export default AuthReponseModel;
