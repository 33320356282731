import httpClient from '../../../api/httpClient';
import RegisterModel from '../models/RegisterModel';
import AuthReponseModel from '../models/AuthReponseModel';

const ENDPOINT_URL = '/market/register';

export const registerPartner = (registrationInformation: RegisterModel) => {
  return httpClient
    .post(ENDPOINT_URL, registrationInformation)
    .then((response) => new AuthReponseModel(response))
    .catch((error) => error);
};
