class RegisterModel {
  public name: string;
  public email: string;
  public password: string;
  public password_confirmation: string;
  public is_independent: 0 | 1; // 0 => company, 1 => independent
  public mobile: string;
  public looking_for_ideas: 0 | 1; // 0 => no, 1 => yes
  public application_type: 0 | 1; // 0 => enterprise merchant, 1 => ZAM
  public country: string;

  constructor(arg: any) {
    this.name = arg.name;
    this.email = arg.email;
    this.password = arg.password;
    this.password_confirmation = arg.password_confirmation;
    this.is_independent = arg.is_independent;
    this.mobile = arg.mobile;
    this.looking_for_ideas = arg.looking_for_ideas;
    this.application_type = arg.application_type;
    this.country = arg.country;
  }
}

export default RegisterModel;
