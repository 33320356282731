import { reactive } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';

const useRegisterTechInfo = () => {
  const registerTechInfoState = reactive({
    partnerType: {
      label: '',
      value: '',
    },
    appIntendedFor: {
      label: '',
      value: '',
    },
    isLookingForIdea: {
      label: '',
      value: '',
    },
    acceptTerms: false,
  });

  const rules = {
    partnerType: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    appIntendedFor: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    isLookingForIdea: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    acceptTerms: {
      required,
      valid: sameAs(true),
    },
  };

  const vTech$ = useVuelidate(rules, registerTechInfoState);

  const onTechDataChange = (
    changedValue: Record<string, string>,
    property: 'partnerType' | 'appIntendedFor' | 'isLookingForIdea',
  ) => {
    vTech$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    registerTechInfoState[property].label = selectedValue.label;
    registerTechInfoState[property].value = selectedValue.value;
  };

  const onAcceptTermsChange = (changedValue: Record<string, boolean>) => {
    vTech$.value.acceptTerms.$touch();
    registerTechInfoState.acceptTerms = changedValue.isChecked;
  };

  return {
    registerTechInfoState,
    vTech$,
    onTechDataChange,
    onAcceptTermsChange,
  };
};

export default useRegisterTechInfo;
