class UpdatePasswordModel {
  public email: string;
  public password: string;
  public password_confirmation: string;
  public signature: string;

  constructor(arg: any) {
    this.email = arg.email;
    this.password = arg.password;
    this.password_confirmation = arg.password_confirmation;
    this.signature = arg.signature;
  }
}

export default UpdatePasswordModel;
