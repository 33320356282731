import { reactive, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength, email, maxLength, sameAs } from '@vuelidate/validators';
import { RegisterStateType, InputFieldsEnum } from '../../types/authTypes';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';

const useRegisterPersonalInfo = () => {
  const registerState = reactive<RegisterStateType>({
    fullName: '',
    emailAddress: '',
    mobileNumber: '',
    password: '',
    passwordConfirm: '',
    country: {
      value: '',
    },
  });

  const rules = {
    fullName: {
      required,
      fullName: helpers.withMessage('Full name is required', required),
    },
    emailAddress: {
      required,
      email,
      emailAddress: helpers.withMessage('E-mail is not valid', required),
    },
    mobileNumber: {
      required,
      mobileNumber: helpers.withMessage('Mobile number is required', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    password: {
      required,
      minLength: minLength(8),
      password: helpers.withMessage('Password is not valid', required),
      digitsAndNumbersOnly: function (value: string) {
        return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value);
      },
    },
    passwordConfirm: {
      required,
      passwordConfirm: helpers.withMessage('Passwords do not match', (value: string) => {
        return minLength(8) && value === registerState.password;
      }),
    },
    country: {
      value: {
        required,
      },
    },
  };

  const v$ = useVuelidate(rules, registerState);

  const onDataChange = (changedValue: Record<string, string>, dataProperty: InputFieldsEnum) => {
    v$.value[dataProperty].$touch();
    registerState[dataProperty] = changedValue.value;
  };

  const onCountrySelect = (changedValue: any) => {
    v$.value.country.$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    registerState.country.value = selectedValue.label;
  };

  const isPasswordValid = computed(() => v$.value.password.$dirty && v$.value.password.$invalid);

  return {
    registerState,
    v$,
    isPasswordValid,
    onDataChange,
    onCountrySelect,
  };
};

export default useRegisterPersonalInfo;
