import httpClient from '../../../api/httpClient';
import LoginModel from '../models/LoginModel';
import AuthReponseModel from '../models/AuthReponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/partner-login';

export const loginPartner = (loginInformation: LoginModel): Promise<AuthReponseModel | ErrorModel> => {
  return httpClient
    .post(ENDPOINT_URL, loginInformation)
    .then((response) => new AuthReponseModel(response))
    .catch((error) => error);
};
