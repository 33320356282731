import httpClient from '../../../api/httpClient';
import BaseModel from '@/api/models/BaseModel';

const ENDPOINT_URL = '/market';

export const verifyEmail = (email: string, signature: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}/forgot-password/verification?email=${email}&signature=${signature}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const confirmEmail = (email: string, signature: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}/confirm-mail/verification?email=${email}&signature=${signature}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
