import Vue from 'vue';
import store from '@/store';
import { defineComponent, reactive, ref } from '@vue/composition-api';
import BaseText from '../../../../../components/text/BaseText.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidButton,
  ZidTextarea,
  ZidCheckbox,
} from '@zidsa/ui';
import { COUNTRIES_LIST } from '../../../../../helpers/constants';
import useRegisterPersonalInfo from '../../../helpers/hooks/useRegisterPersonalInfo';
import { PARTNER_TYPES_LIST, APPLICATION_INTENTIONS_LIST, IS_LOOKING_FOR_IDEAS } from '../../../helpers/dropdownInfo';
import useRegisterTechInfo from '../../../helpers/hooks/useRegisterTechInfo';
import RegisterReponseModel from '../../../models/RegisterModel';
import { registerPartner } from '../../../api/register';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { saveAuthStateToLocalStorageAndGlobalState } from '../../../helpers/authState';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { TERMS_OF_USE } from '@/helpers/constantLinks';
import { PatnerTypeEnum, PatnerTypeValuesEnum, AppTypeEnum, AppTypeValuesEnum } from '../../../types/authTypes';
import ErrorModel from '@/api/models/ErrorModel';
import { getPermissions } from '@/api/top-level-apis/permissions/permissions';
import { saveGrantedPermissionsToLocalStorageAndGlobalState } from '@/api/useAuthStatus';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

const { useActions } = createNamespacedHelpers('authentication');
export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidButton,
    ZidTextarea,
    ZidCheckbox,
  },
  setup() {
    const { registerState, v$, isPasswordValid, onDataChange, onCountrySelect } = useRegisterPersonalInfo();

    const { registerTechInfoState, vTech$, onTechDataChange, onAcceptTermsChange } = useRegisterTechInfo();

    const countriesList = reactive({
      options: COUNTRIES_LIST,
    });

    const partnerTypes = reactive({
      options: PARTNER_TYPES_LIST,
    });

    const applicationIntentions = reactive({
      options: APPLICATION_INTENTIONS_LIST,
    });

    const isLookingForIdeasOptions = reactive({
      options: IS_LOOKING_FOR_IDEAS,
    });

    const loadingSubmit = ref(false);
    const termsOfUseLink = ref(TERMS_OF_USE);

    const { setAccountCreationStepsState, setAnAppHasBeenCreated } = useActions([
      'setAccountCreationStepsState',
      'setAnAppHasBeenCreated',
    ]);

    const findPartnerType = (partner: string) => {
      if (partner === PatnerTypeEnum.Independent) return PatnerTypeValuesEnum.Independent;
      else if (partner === PatnerTypeEnum.Employed) return PatnerTypeValuesEnum.Employed;
    };

    const findAppType = (app: string) => {
      if (app === AppTypeEnum.ZAM) return AppTypeValuesEnum.ZAM;
      if (app === AppTypeEnum.EnterpriseMerchant) return AppTypeValuesEnum.enterpriseMerchant;
      if (app === AppTypeEnum.ThemeDeveloper) return AppTypeValuesEnum.ThemeDeveloper;
    };

    const onSubmit = async () => {
      const { fullName, emailAddress, mobileNumber, password, passwordConfirm, country } = registerState;

      loadingSubmit.value = true;

      const registerDataModel = new RegisterReponseModel({
        name: fullName,
        email: emailAddress,
        mobile: mobileNumber,
        password,
        password_confirmation: passwordConfirm,
        country: country.value,
        is_independent: findPartnerType(registerTechInfoState.partnerType.value),
        application_type: findAppType(registerTechInfoState.appIntendedFor.value),
        looking_for_ideas: registerTechInfoState.isLookingForIdea.value ? 1 : 0,
      });

      const response = await registerPartner(registerDataModel);

      if (response instanceof ErrorModel || !response.partner) {
        Vue.$toast.error(response.message.validations[0]?.errors[0]);
        loadingSubmit.value = false;
        return;
      }

      await saveAuthStateToLocalStorageAndGlobalState(response);
      store.dispatch('setIsAppLoading', true);
      store.dispatch('setPartnerID', response.partner.id);

      if (!response.partner.is_account_owner) {
        const permissionsRes = await getPermissions();
        if (!(permissionsRes instanceof ErrorModel) && permissionsRes.permissions)
          await saveGrantedPermissionsToLocalStorageAndGlobalState(permissionsRes.permissions);
        else await saveGrantedPermissionsToLocalStorageAndGlobalState();
      }

      if (response.partner.role === null)
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          user_hash: response.partner.intercom_user_hash,
          user_id: `${response.partner.id}`,
          api_base: 'https://api-iam.intercom.io',
          zam_ID: response.partner.id,
          name: response.partner.name,
          email: response.partner.email,
          language_override: I18nLocaleEnum.en,
        });

      router.replace({ name: RoutingRouteEnum.Home }).catch(() => {
        /**/
      });

      const welcomeResponse = await welcomePartner();
      if (!(welcomeResponse instanceof ErrorModel) && welcomeResponse.payload) {
        await setAccountCreationStepsState(welcomeResponse.payload.partner_email);
        await setAnAppHasBeenCreated(welcomeResponse.payload.app_created);
        saveAuthStateToLocalStorageAndGlobalState(
          response,
          welcomeResponse.payload.partner_email,
          welcomeResponse.payload.app_created,
        );
      }
      Vue.$toast.success(`Welcome to Partner Dashboard, ${response.partner.name}`);

      loadingSubmit.value = false;
    };

    const onCancelClicked = () => {
      registerState.fullName = '';
      registerState.emailAddress = '';
      registerState.mobileNumber = '';
      registerState.password = '';
      registerState.passwordConfirm = '';
      registerState.country.value = '';
      registerTechInfoState.partnerType.label = '';
      registerTechInfoState.partnerType.value = '';
      registerTechInfoState.appIntendedFor.label = '';
      registerTechInfoState.appIntendedFor.value = '';
      registerTechInfoState.isLookingForIdea.label = '';
      registerTechInfoState.isLookingForIdea.value = '';
      router.push({ name: RoutingRouteEnum.LogIn }).catch((err) => {
        /**/
      });
    };

    return {
      registerState,
      registerTechInfoState,
      countriesList,
      v$,
      vTech$,
      isPasswordValid,
      partnerTypes,
      applicationIntentions,
      isLookingForIdeasOptions,
      loadingSubmit,
      termsOfUseLink,
      onDataChange,
      onCountrySelect,
      onCancelClicked,
      onSubmit,
      onTechDataChange,
      onAcceptTermsChange,
    };
  },
});
