import { reactive, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';

export const useChangePassword = () => {
  const formState = reactive({
    password: '',
    passwordConfirm: '',
  });

  const rules = {
    password: {
      required,
      minLength: minLength(8),
      password: helpers.withMessage('Password is not valid', required),
      containsLowercase: function (value: string) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value: string) {
        return /[0-9]/.test(value);
      },
    },
    passwordConfirm: {
      minLength: minLength(8),
      passwordConfirm: helpers.withMessage('Passwords do not match', required),
    },
  };

  const v$ = useVuelidate(rules, formState);

  const isPasswordValid = computed(() => v$.value.password.$dirty && v$.value.password.$invalid);
  const isConfirmationInvalid = computed(
    () => v$.value.passwordConfirm.$dirty && v$.value.passwordConfirm.$model !== v$.value.password.$model,
  );

  const onDataChange = (changedValue: Record<string, string>, dataProperty: 'password' | 'passwordConfirm') => {
    v$.value[dataProperty].$touch();
    formState[dataProperty] = changedValue.value;
  };

  return {
    formState,
    v$,
    isPasswordValid,
    isConfirmationInvalid,
    onDataChange,
  };
};

export default useChangePassword;
